import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import { graphql } from 'gatsby'

const EmailTemplates = ({data}) => {
  return (
    <Layout>
      <Title text='Email Templates'></Title>
      
      {data.allMarkdownRemark.edges.map(({node}) => (
        <div>
          <h1>{node.frontmatter.title}</h1>
          <artcle dangerouslySetInnerHTML={{ __html: node.html }} />
        </div>
      ))}
    </Layout>
    )
}

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "EmailTemplate"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            image
            keywords
            type
            target
          }
          html
        }
      }
    }
  }
  `


export default EmailTemplates
